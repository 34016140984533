.library {
  position: fixed;
  box-shadow: 2px 2px 50px rgb(221, 221, 221);
  border-radius: 1rem;
  top: 0;
  left: 0;
  width: 20rem;
  height: 100%;
  overflow: scroll;
  background: white;
  transform: translateX(-100%);
  transition: all 0.5s ease;
  opacity: 0;

  h2 {
    padding: 2rem;
  }
}

.library-song {
  display: flex;
  align-items: center;
  padding: 1rem 2rem 1rem 2rem;
  cursor: pointer;
  transition: all 0.75s ease-out;

  img {
    width: 30%;
  }
  &:hover {
    background: rgb(235, 235, 235);
  }
}
.song-description {
  padding-left: 1rem;
  h3 {
    font-size: 1rem;
  }
  h4 {
    font-size: 0.7rem;
  }
}

.delete-song {
  display: block;
  position: absolute;
  right: 1rem;
}
.hidden {
  display: none;
}
/* The emerging W3C standard
   that is currently Firefox-only */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.7) transparent;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 5px;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.7);
  border-radius: 20px;
  border: transparent;
}

.selected {
  background: rgb(194, 208, 253);
}
.active-library {
  transform: translateX(0%);
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .library {
    width: 100%;
  }
  .delete-song {
    margin-right: 1.5rem;
  }
}
