nav {
  min-height: 10vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .lib-btn {
    background: transparent;
    border: none;
    cursor: pointer;
    user-select: none;
    padding: 0.5rem;
    border: 2px solid rgb(65, 65, 65);
    transition: all 0.3s ease;
    &:hover {
      background: rgb(65, 65, 65);
      color: white;
    }
  }
  .library-active {
    background: rgb(65, 65, 65);
    color: white;
  }
  h1 {
    user-select: none;
  }
}

@media screen and (max-width: 768px) {
  nav {
    display: flex;
    flex-direction: column;
    button {
      z-index: 10;
      position: absolute;
      right: 0;
      top: 0;
      border: 1px solid rgb(65, 65, 65);
    }
    .song-search {
      margin-top: 1.3rem;
      z-index: 0;
    }
    h1 {
      position: absolute;
      left: 0.8rem;
      top: 0.8rem;
    }
  }
}
